.coupon-inner-iteam {
    color: #fff;
    background-color: #888888;
    height: 20px;
}

@keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

#coupon-code {
    white-space: nowrap;
    overflow: hidden;
    animation: slideLeft 16s linear infinite;
    margin: 0px auto;
    display: inline-block;
    white-space: nowrap;
    min-width: 90%;
}

.marquee-w {
    position: relative;
    display: block;
    width: 750px;
    height: 140px;
    top: 505;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.marquee {
    position: absolute;
    display: block;
    margin: auto auto;
    white-space: nowrap;
    overflow: hidden;
    min-width: 100%;
}