.booking-getway-wrapper {
  background-color: #f5f6fa;
  background-image: url("../../../images/booking.png");
  background-repeat: no-repeat;
  background-size: 50%;
  height: calc(100% - 56px);
}

.seprator-52 {
  padding: 52px 0 0 0;
}

.booking-getway-inner {
  background-color: #fff;
  width: 50%;
  margin-left: calc(100% - 50%);
  padding: 40px 0 0 0;
  height: 100%;
}

.booking-field {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 14px 0;
}

.btn-select {
  padding: 4px 10px;
  border-radius: 6px;
  color: #888888;
  background-color: #fff;
  border: #888888 solid 0px;
  box-shadow: 0px 0px 0px 0.1px;
  margin: 5px 15px 5px 0px;
  width: 85px;
  height: 30px;
  font-size: 14px;
}


.btn-selected {
  padding: 4px 10px;
  border-radius: 6px;
  color: #fff;
  background-color: #d02429;
  border: #888888 solid 0px;
  box-shadow: 0px 0px 0.5px 0.9px;
  margin: 5px 15px 5px 0px;
  width: 85px;
  height: 30px;
}

.booking-note {
  font-size: 10px;
  color: #888888;
  font-weight: 500;
  margin: 0px;
}

.head-count-wrapper {
  height: 30px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d02429;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0.1, 0.1, 0.1, 0.1);
}

.head-count-wrapper span {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  cursor: pointer;
}

.plus {
  background-color: #fff;
  height: 30px;
  min-width: 10px;
  width: 70px;
  padding: 0px 0 0 0;
  border-radius: 0 2px 2px 0px;
  border: #D9D9D9 solid 1px;
}

.minus {
  background-color: #fff;
  height: 30px;
  min-width: 12px;
  width: 65px;
  padding: 0px;
  border-radius: 2px 0 0 2px;
  border: #D9D9D9 solid 1px;
}

.menu-frame{
    border:1px solid #dfdada;
    justify-content: center;
    align-items:center;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.head-count-wrapper .num {
  font-size: 14px;
  color: #fff;
  padding: 0px 5px;
}

.booking-form-date-input {
  border-radius: 6px;
  background-color: #fff;
  color: #888888;
  font-size: 12px;
  border: #d9d9d9 solid 1px;
  padding: 4px;
}

.booking-form-time-input {
  border-radius: 6px;
  background-color: #fff;
  color: #888888;
  font-size: 12px;
  border: #d9d9d9 solid 1px;
  padding: 5px;
}

.booking-form-input {
  border-radius: 6px;
  background-color: #fff;
  color: #888888;
  font-size: 12px;
  border: #d9d9d9 solid 1px;
  padding: 6px 0px 6px 20px;
}

.booking-form-name-input {
  border-radius: 6px;
  background-color: #fff;
  color: #888888;
  font-size: 12px;
  border: #d9d9d9 solid 1px;
  padding: 6px 0px 6px 20px;
  width: 100%;
}

.booking-form-single-input {
  border-radius: 6px;
  background-color: #fff;
  color: #888888;
  font-size: 12px;
  border: #d9d9d9 solid 1px;
  padding: 6px 0px 6px 20px;
  width: 109px;
}

.btn-coupon {
  padding: 4px 10px;
  border-radius: 6px;
  color: #888888;
  background-color: #f5f6fa;
  border: #888888 solid 0px;
  box-shadow: 0px 0px 0.5px 0.9px;
  margin: 0px 0px 0px 10px;
  width: 85px;
  height: 30px;
  font-size: 14px;
}

.coupon-wrapper {
  width: 300px;
  max-width: 100%;
  position: relative;
  margin: 10px 0px;
}

.coupon-wrapper input {
  border-radius: 6px;
  background-color: #f5f6fa;
  color: #888888;
  font-size: 12px;
  border: #d9d9d9 solid 1px;
  padding: 6px 0px 6px 20px;
  width: 205px;
}

.coupon-wrapper button {
  background-color: #d02429;
  border-radius: 5px;
  height: 29px;
  position: absolute;
  right: 102px;
  top: 9%;
  font-size: 14px;
  padding: 0px 20px 0px 20px;
  color: #fff;
  border: #d02429 solid 1px;
}

.booking-bottom-section {
  width: 100%;
  height: 80px;
  background-color: #eeeeee;
  margin: 14px 0 0 0;
  padding: 45px;
}

.booking-bottom-section h3 {
  color: #d02429;
  padding: 0px 190px 0 0px;
}

.booking-button {
  background-color: #d02429;
  color: #fff;
  font-size: 18px;
  width: 286px;
  height: 46px;
  border-radius: 6px;
  border: none;
}

.btn-dyo-save {
  margin: 31px 0 0 0;
  width: 120px;
  height: 26px;
  border-radius: 5px;
  background-color: #d02429;
  color: #fff;
  border: none;
  font-size: 14px;
}

.booking-getway-inner-items {
  max-width: 590px;
  padding: 0px 0px 0px 85px;
}

.booking-scroller-iteams {
  overflow-y:scroll;
  max-height: 400px;
  scrollbar-width: none;
  margin: 0 auto;
}

.address-gatway {
  max-width: 590px;
  overflow-y: auto;
  max-height: 100%;
  scrollbar-width: none;
  height: 600px;
}

.spacing-head {
  padding: 0px;
  margin: 0px auto;
}

.booking-getway-inner-items h2 {
  margin: 0px auto;
  background-color: #fff;
}

.booking-getway-inner-items span {
  color: #d02429;
}

.booking-getway-inner-items label {
  font-size: 18px;
  font-weight: 500;
  color: #2a2b2c;
}

.accordion-layout {
  width: 500px;
  height: 20px;
  background-color: #f5f6fa;
  font-size: 14px;
  border: none solid;
}



.accordion-item {
  border: none;
  background-color: #f5f6fa;
}

/* Plus button style */
.accordion-icon {
  float: left;
  margin-right: 5px;
}

.accordion-body {
  background-color: #E7E7E7;
  font-size: 14px;
  padding: 10px;
}

.accordion-body p {
  background-color: #E7E7E7;
  margin: 1px auto;
  color: #2A2B2C;
}


/* Minus button style */
.accordion-button-sym::before {
  content: "+";
  font-size: 18px;
  margin: 0px 10px 0px 10px;
  color: #d02429;
}

.accordion-button-sym[aria-expanded="true"]::before {
  content: "-";
}

.accordion-button-sym {
  background-color: transparent;
  color: #545454;
  border: none;
  padding: 0;
  text-align: left;
  width: 100%;
}

.address-layout {
  border: #E7E7E7 solid 1px;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.option {
  display: flex;
  flex-direction: row;
  gap: 15px;
  font-size: 13px;
}

.option button.active {
  background: #fff3e0;
  padding-right: 27px;
}

.option button {
  font-weight: 400;
  color: #000000ba;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  background-color: #fff;
  justify-content: center;
  padding: 10px 20px 10px 11px;
  border: 2px solid lightgray;
}

.red {
  background-color: #d02429;
  color: #fff;
}

.accordion-details-body {
  background-color: #E7E7E7;
  font-size: 12px;
  padding: 7px 0px 8px 0px;
}

.booking-address {
  color: #545454;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 15px;
}

.date-merge {
  width: 110px;
}

.time-merge {
  width: 85px;
  margin: 0px 0px 0px 1px;
}

.address-layout p {
  font-size: 12px;
  margin: 0px auto;
}

.selection {
  list-style-type: none;
}

.booking-details h4 {
  font-size: 20px;
  margin: 0px auto;
}

.booking-details p {
  padding: 0px 0px 15px 50px;
  font-size: 20px;
  margin: 0px auto;
}

.booking-details img {
  width: 40px;
  height: 40px;
}

.booking-iteam {
  margin: 20px;
  padding: 30px;
}


@media (max-width: 600px) {
  .booking-getway-wrapper {
    background-size: 100%;
    height: 60vh;
  }

  .booking-getway-inner {
    width: 100%;
    margin-left: calc(50% - 50%);
    height: 700px;
  }

  .booking-getway-inner-items {
    max-width: 100%;
    padding:0px 30px;
  }

  .booking-getway-inner-items label {
    font-size: 16px;
  }

  .btn-select {
    padding: 0px;
    box-shadow: 0px 0px 0px 0.1px;
    margin: 0px 10px 10px 0px;
    width: 75px;
    height: 25px;
    font-size: 10px;
  }

  .btn-selected {
    padding: 0px 0px;
    border-radius: 6px;
    color: #fff;
    background-color: #d02429;
    border: #888888 solid 0px;
    box-shadow: 0px 0px 0.5px 0.9px;
    margin: 0px 10px 5px 0px;
    width: 76px;
    height: 25px;
    font-size: 12px;
  }

  .seprator-52 {
    padding: 35px 0 0 0;
  }

  .head-count-wrapper {
    height: 25px;
    width: 55px;
  }

  .minus,
  .plus {
    height: 25px;
  }

  .booking-bottom-section h3 {
    color: #d02429;
    padding: 0px 80px 0 0px;
  }

  .accordion-body {
    background-color: #E7E7E7;
    font-size: 12px;
    padding: 1px 30px 8px 30px;
  }

  .booking-address {
    font-size: 15px;
  }

  .address-layout {
    padding: 6px 0px 0px 8px;
    margin: 0 0 5px 0;
  }

  .booking-form-single-input {
    font-size: 8px;
    padding: 6px 0px 6px 10px;
    width: 77px;
    border-radius: 2px;
  }

  .spacing-head {
    padding: 0px;
    font-size: 15px;
  }

  .booking-button {
    background-color: #d02429;
    color: #fff;
    font-size: 12px;
    width: 112px;
    height: 25px;
    border-radius: 6px;
  }

  .booking-form-input {
    border-radius: 2px;
    background-color: #fff;
    color: #888888;
    font-size: 8px;
    border: #d9d9d9 solid 1px;
    padding: 6px 0px 6px 20px;
  }

  .booking-form-name-input {
    border-radius: 2px;
    background-color: #fff;
    color: #888888;
    font-size: 8px;
    border: #d9d9d9 solid 1px;
    padding: 6px 0px 6px 20px;
    width: 133px;
  }

  .booking-details-content h6 {
    font-size: 10px;
    color: #888888;
  }

  .booking-details-content p {
    font-size: 10px;
    color: #888888;
  }

  .booking-details h4 {
    font-size: 12px;
    margin: 0px auto;
  }

  .booking-details p {
    font-size: 11px;
    padding: 0px 0px 0px 30px;
  }

  .booking-details img {
    width: 23px;
  }

  .booking-iteam {
    margin: 20px 30px;
    padding: 23px 0px 1px 28px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .booking-getway-wrapper {
    background-size: 100%;
    height: 60vh;
  }

  .booking-getway-inner {
    width: 100%;
    margin-left: calc(50% - 50%);
  }

  .booking-getway-inner-items {
    max-width: 600px;
    margin: 0 0 0 0;
  }

  .booking-getway-inner-items label {
    font-size: 18px;
  }


  .booking-details h4 {
    font-size: 14px;
    margin: 0px auto;
  }

  .booking-details p {
    font-size: 13px;
    padding: 0px 0px 0px 35px;
  }

  .booking-details img {
    width: 26px;
  }

}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  .booking-getway-wrapper {
    background-size: 100%;
    height: 60vh;
  }

  .booking-getway-inner {
    width: 100%;
    margin-left: calc(50% - 50%);
    padding: 50px 0px 0 0px;
  }

  .getway-inner-items {
    max-width: 500px;
    margin: 0 auto;
  }

  .journal-getway-inner-items {
    max-width: 500px;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 490px;
    height: 500px;
  }

  .booking-getway-inner-items {
    max-width: 600px;
    margin: 0 0 0 0;
  }

  .booking-getway-inner-items label {
    font-size: 18px;
  }

  .booking-getway-inner-items h2 {
    font-size: 35px;
  }

  .booking-details h4 {
    font-size: 16px;
    margin: 0px auto;
  }

  .booking-details p {
    font-size: 15px;
    padding: 0px 0px 0px 35px;
  }

  .booking-details img {
    width: 26px;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .booking-getway-wrapper {
    height: 100vh;
  }

  .booking-getway-inner {
    width: 50%;
    height: 100%;
    margin-left: calc(100% - 50%);
    padding: 40px 0px 0 0px;
    max-height: 1000px;
  }

  .booking-getway-inner-items {
    max-width: 800px;
    margin: 0 auto;
  }

  .booking-getway-inner-items label {
    font-size: 16px;
  }

  .booking-getway-inner-items h2 {
    font-size: 25px;
  }

  .seprator-52 {
    padding: 35px 0 0 0;
  }

  .booking-bottom-section {
    width: 100%;
    height: 60px;
    background-color: #eeeeee;
    margin: 0px 0 0 0;
    padding: 0px;
  }

  .booking-bottom-section h3 {
    color: #d02429;
    padding: 0px 50px 0 0px;
  }

  .booking-button {
    background-color: #d02429;
    color: #fff;
    font-size: 18px;
    width: 286px;
    height: 46px;
    border-radius: 50px;
  }

  .booking-details h4 {
    font-size: 18px;
    margin: 0px auto;
  }

  .booking-details p {
    font-size: 17px;
    padding: 0px 0px 0px 35px;
  }

  .booking-details img {
    width: 26px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1440px) {

  .booking-getway-wrapper {
    background-size: 50%;
    height: 100vh;
  }

  .booking-getway-inner {
    width: 50%;
    height: 100%;
    margin-left: calc(100% - 50%);
    padding: 60px 0px 0 0px;
    max-height: 1000px;
  }

  .booking-getway-inner-items {
    max-width: 900px;
    margin: 0 auto;
    padding: 0px 100px 30px 60px;
  }

  .booking-getway-inner-items label {
    font-size: 18px;
  }

  .booking-getway-inner-items h2 {
    font-size: 35px;
  }

  .seprator-52 {
    padding: 35px 0 0 0;
  }

  .booking-bottom-section {
    width: 100%;
    height: 60px;
    background-color: #eeeeee;
    margin: 0px 0 0 0;
    padding: 45px;
  }

  .booking-bottom-section h3 {
    color: #d02429;
    padding: 0px 50px 0 0px;
  }

  .booking-button {
    background-color: #d02429;
    color: #fff;
    font-size: 18px;
    width: 286px;
    height: 46px;
    border-radius: 50px;
  }

}