@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

:root {
  /*color Scheme*/

  --dyo-base-red: #d02429;
  --dyo-white: #ffffff;
  --dyo-primery-font: #2a2b2c;
  --dyo-base-font: #888888;
  --dyo-neutral-gray: #f7f7f7;
  --dyo-neutral-brown: #36312b;
  --dyo-input: #f5f6fa;
  --dyo-neutral-300: #4a3d3d;
  --dyo-neutral-950: #000;
  --dyo-background: #f2f5f2;

  /*Font-weight*/
  --dyo-normal: 400;
  --dyo-semibold: 500;
  --dyo-bold: 600;
  --dyo-bolder: 700;

  /*Font-size*/
  --dyo-fs-small: 0.688rem;
  --dyo-fs-regular: 0.813;
  --dyo-fs-base: 1rem;

  /**/
  --dyo-leading-6: 0.375rem;
  --dyo-leading-12: 0.75rem;
  --dyo-leading-14: 0.875rem;
  --dyo-leading-16: 1rem;
  --dyo-leading-18: 1.125rem;
  --dyo-leading-20: 1.25rem;
  --dyo-leading-22: 1.375rem;
  --dyo-leading-24: 1.5rem;
  --dyo-leading-26: 1.625rem;
  --dyo-leading-30: 1.875;
  --dyo-leading-32: 2rem;
  --dyo-leading-36: 2.25rem;
  --dyo-leading-40: 2.5rem;
  --dyo-leading-46: 2.875rem;
  --dyo-leading-50: 3.125rem;
  --dyo-leading-58: 3.625rem;
  --dyo-leading-64: 4rem;
  --dyo-leading-70: 4.375rem;
  --dyo-leading-96: 6rem;
  --dyo-leading-190: 11.875rem;
}

* {
  font-family: "lato", sans-serif;
}

body {
  padding: 0;
  margin: 0;
  border: 0;
  text-rendering: optimizeLegibility;
  font-size: var(--dyo-fs-base);
  line-height: var(--dyo-leading-22);
}

/* DYO Logo */
.brand-logo {
  width: 175px;
}

/* DYO Logo */

.btn {
  padding: 10px 28px;
}

.dyo-button {
  background-color: var(--dyo-base-red);
  color: var(--dyo-white);
  width: 190px;
  height: 45px;
  border: none;
}
 

.dyo-nav-button {
  background-color: var(--dyo-base-red);
  color: var(--dyo-white);
  width: 110px;
  height: 34px;
  border: none;
}

.btn-rounded {
  border-radius: 30px;
}

.btn-Popover {
  color: #d02429;
  border: none;
}

.btn-dyo-primary {
  background-color: #d02429;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin: 11px 0 11px 0;
}

.btn-dyo-primary:hover {
  background-color: #a41d2b;
  color: #ffff;
}

.btn-dyo-secondary {
  font-size: 12px;
  border: 1px solid #d02429;
  color: #d02429;
  padding: 5px 17px 5px 17px;
}

.service-card:hover{ 
  box-shadow: 0px 2px 2px 2px rgb(235, 235, 235); 
} 
.service-btn{ 
  font-size: 12px; 
  border: 1px solid #d02429; 
  color: #d02429; 
  padding: 5px 17px 5px 17px; 
  background: none; 
  border-radius: 18px; 
} 
.service-btn:hover{ 
  background-color:#d02429 ; 
  color: white; font-weight: 600; 
  border: none; 
}

.navbar-toggler-icon {
  /* display: inline-block;
  border-color: #fff;
  background-color: #9e1919;
    */ background-image: url("../src/images/clipart365828.png");
}

.home{
  min-height: 100vh;
  display: flex;
  align-items: center;
  background:url("https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/NewBanner.svg") ;
  background-size: cover;
  background-position: center;
  padding: 2rem 7%;
 }

 .home .content{
  max-width: 50rem;
  padding: 0px 0px 0px 0px;
 }

 .home .content h3{
  font-size: 66px;
  color: #fff;
  font-family: 'Dancing Script';
  font-weight: 400;
 }

 .home .content p {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.8;
  padding: 30px 0px;
  color: #eee;
  letter-spacing: 3px;
  line-height: 34px;
  max-width: 40rem;
  margin: 10px 0px;
}

.content span{
  color: #D02429;
}
.high-lite-line {
  font-family: 'Dancing Script';
}

.btn-dyo-banner {
  width: 192px;
  height: 45px;
  font-size: 16px;
  border-radius: 6px;
  border: none;
  background-color: #d02429;
  color: #fff;
}

tr {
  border: 1px solid #ffffff;
}

th {
  font-size: 16px;
  font-weight: 600;
  margin: 100px;
  border-bottom: 1px solid #d02429;
  padding: 15px 2px 22px 2px;
}

td {
  font-size: 14px;
  font-weight: 500;
}

.status-text {
  font-size: 10px;
  font-weight: 500;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #ffffff !important;
  opacity: 133.75 !important;
  border: #d02429 solid 2px;
  border-radius: 50%;
}

.slick-dots li.slick-active button:before {
  color: #d02429 !important;
  opacity: 133.75 !important;
  border-radius: 50%;
  border: #d02429 solid 1px;
  background: #d02429;
}

.slick-next {
  display: none !important;
}

.navbar-option {
  font-size: var(--dyo-leading-16);
  color: var(--dyo-white);
}

.nav-link:hover {
  color: var(--dyo-white);
  border-bottom: #d02429 solid 2px;
  opacity: 0.2s;
}

#more {
  display: none;
}

.slick-dots li.slick-active button:before {
  opacity: 133.75;
  color: #dc3545;
}

.seprator-100 {
  padding: 50px 0;
}

.section-title {
  text-align: center;
  position: relative;
  min-height: 123px;
  margin: 0px 0px 64px 0px;
}

.section-title h2 {
  font-size: 50px;
  margin: 0;
  line-height: 55px;
  font-family: "Dancing Script";
  color: #d02429;
  margin-bottom: 11px;
}

.section-title p {
  line-height: 24px;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.section-title::after {
  content: "";
  width: 80px;
  height: 1px;
  position: absolute;
  background-color: #d02429;
  bottom: 1px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.section-title::before {
  content: "\f005";
  font-family: "FontAwesome";
  position: absolute;
  bottom: -2px;
  z-index: 99;
  color: #d02429;
  margin: 0 auto;
  width: 21px;
  height: 12px;
  background: #fff;
  right: 0;
  left: 0;
}

.our-story-section-title {
  text-align: center;
  position: relative;
  min-height: 123px;
}

.our-story-section-title h2 {
  font-size: 50px;
  margin: 0;
  line-height: 55px;
  font-family: "Dancing Script";
  color: #d02429;
  margin-bottom: 11px;
}

.our-story-section-title p {
  line-height: 24px;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.our-story-section-title::after {
  content: "";
  width: 80px;
  height: 1px;
  position: absolute;
  background-color: #d02429;
  bottom: 1px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.our-story-section-title::before {
  content: "\f005";
  font-family: "FontAwesome";
  position: absolute;
  bottom: -2px;
  z-index: 99;
  color: #d02429;
  margin: 0 auto;
  width: 21px;
  height: 12px;
  background: #f7f7f7;
  right: 0;
  left: 0;
}

.discover-inner {
  max-width: 1073px;
  margin: 0 auto;
}

.discover-inner h4 {
  font-weight: 700;
}

.discover-inner p {
  color: #888;
  font-size: 14px;
}

.quick-book-iteams {
  max-width: 80%;
}

.quick-book-iteams h6 {
  font-weight: 600;
  font-size: 18px;
  padding: 19px 20px 5px 19px;
}

.quick-book-iteams p {
  font-weight: 400;
  padding: 10px 10px 17px 19px;
  font-size: var(--dyo-leading-14);
  color: var(--dyo-base-font);
}

.our-story-section {
  background-color: #f7f7f7;
}

.add-section {
  background-image: url(../src/images/advertisment.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: relative;
  height: 50vh;
}

.add-section h2 {
  color: #d02429;
  font-family: "Dancing Script";
  font-size: 50px;
  font-weight: 700;
}

.add-section p {
  color: #fff;
  font-size: 22px;
  border: none;
  padding: 10px 0 0 0;
}

.testimonial-containe {
  font-size: var(--dyo-leading-14);
  color: var(--dyo-primery-font);
}

.testimonial-card {
  transform: translate(0px, -110px);
}

.testimonial-layout {
  margin: 0px 10px 0px 10px;
  width: 391;
  height: 200;
}

.our-inner-iteams {
  position: relative;
}


.content-chef-card {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgb(0, 0, 0, 0.5);
  color: #fafafa;
  justify-content: center;
  align-content: center;
  padding: 280px 0 0 10px;
}

.content:hover {
  opacity: 1;
}

.rating-chef {
  background-color: #d02429;
  padding: 2px 4px;
  border-radius: 3px;
}

.cuisine-spece {
  border-radius: 10px;
  border: #d02429 solid 1px;
  background-color: #ee353a;
  width: 25%;
  padding: 1px 6px 1px 6px;
  margin: 0px 130px 0px 0px;
  font-size: 10px;
}

footer {
  background-color: #36312b;
  padding: 52px 0 11px 0;
  color: #fff;
}

.quick-link h5 {
  font-size: 18px;
  margin-bottom: 24px;
}

.quick-link-inner li {
  margin: 10px 0 9px 0;
  font-size: 12px;
}

.contact-us h5 {
  font-size: 18px;
  margin-bottom: 24px;
}

.contact-us p {
  font-size: 12px;
}

.footer-contact h5 {
  margin: 0 120px 0 0;
  margin-bottom: 24px;
}

.footer-contact a {
  padding: 0 12px 0 12px;
}

.bottom-bar p {
  margin: 11px 0 0 0;
  font-size: 12px;
}

.booking-receipt {
  margin-top: 5%;
}

.testimonial-image {
  width: 55px;
  height: 57px;
  border-radius: 50%;
}

input[className="rating-input"] {
  display: none;
}

.user-rating {
  color: #000;
  background-color: #f7f7f7;
}

.modal-body label {
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0px 5px 0px;
}

.modal-body textarea {
  padding: 10px 10px 5px 10px;
  border: #007bff;
}

.modal-footer button {
  background-color: #fafafa;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .dyo-button {
    background-color: var(--dyo-base-red);
    color: var(--dyo-white);
    width: 60px;
    height: 19px;
    border: none;
    font-size: 6px;
    padding: 0px 0 0px 0;
  }

  .quick-book-iteams {
    max-width: 70%;
  }

  .quick-book-iteams h6 {
    font-weight: 600;
    font-size: 16px;
    padding: 19px 20px 5px 19px;
  }

  .quick-book-iteams p {
    font-weight: 400;
    padding: 10px 10px 17px 19px;
    font-size: var(--dyo-leading-12);
    color: var(--dyo-base-font);
  }

  .content-chef-card {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.5);
    color: #fafafa;
    justify-content: center;
    align-content: center;
    padding: 300px 0 0 10px;
  }

  .testimonial-containe {
    font-size: 10px;
    color: var(--dyo-primery-font);
  }

  .home {
    min-height: 77vh;
    display: flex;
    align-items: center;
    background: url("https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/NewBanner.svg");
    background-size: cover;
    background-position: center;
    height: 78vh;
}

.home .content {
  max-width: 16rem;
  padding: 0px 0px 0px 12px;
}

.home .content h3 {
  font-size: 50px;
  color: #fff;
  font-family: 'Dancing Script';
  font-weight: 400;
}

.home .content p {
  font-size: 16px;
  font-weight: 500;
  padding: 0px;
  color: #eee;
  letter-spacing: 0px;
  max-width: 15rem;
}

.btn-dyo-banner {
  width: 70px;
  height: 25px;
  font-size: 10px;
  border-radius: 3px;
  border: none;
  background-color: #d02429;
  color: #fff;
}

.high-lite-line{
  font-family: 'Dancing Script';
}

}

@media only screen and (min-width: 601px) and (max-width: 768px) {

  .dyo-button {
    background-color: var(--dyo-base-red);
    color: var(--dyo-white);
    width: 100px;
    height: 25px;
    border: none;
    font-size: 9px;
  }

  .quick-book-iteams {
    max-width: 70%;
  }

  .quick-book-iteams h6 {
    font-weight: 600;
    font-size: 16px;
    padding: 19px 20px 5px 19px;
  }

  .quick-book-iteams p {
    font-weight: 400;
    padding: 10px 10px 17px 19px;
    font-size: var(--dyo-leading-12);
    color: var(--dyo-base-font);
  }
  
  .home {
    min-height: 77vh;
    display: flex;
    align-items: center;
    background: url("https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/NewBanner.svg");
    background-size: cover;
    background-position: center;
    height: 78vh;
}

  .home .content {
    max-width: 24rem;
    padding: 0px 0px 0px 12px;
  }

  .home .content h3 {
    font-size: 50px;
    color: #fff;
    font-family: 'Dancing Script';
    font-weight: 400;
  }

  .home .content p {
    font-size: 16px;
    font-weight: 500;
    padding:0px;
    color: #fff;
    letter-spacing: 0px;
    max-width: 22rem;
  }

  .btn-dyo-banner {
    width: 100px;
    height: 30px;
    font-size: 12px;
    border-radius: 3px;
    border: none;
    background-color: #d02429;
    color: #fff;
  }

  .high-lite-line{
    font-family: 'Dancing Script';
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {

  .dyo-button {
    background-color: var(--dyo-base-red);
    color: var(--dyo-white);
    width: 150px;
    height: 35px;
    border: none;
    font-size: 12px;
  }

  .content-chef-card {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.5);
    color: #fafafa;
    justify-content: center;
    align-content: center;
    padding: 20px 0 0 10px;
  }

  .home {
    min-height: 77vh;
    display: flex;
    align-items: center;
    background: url("https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/NewBanner.svg");
    background-size: cover;
    background-position: center;
    height: 78vh;
}

  .home .content {
    max-width: 35rem;
    padding: 0px 0px 0px 12px;
  }

  .home .content h3 {
    font-size: 50px;
    color: #fff;
    font-family: 'Dancing Script';
    font-weight: 400;
  }

  .home .content p {
    font-size: 16px;
    font-weight: 500;
    padding:10px 0px;
    color: #fff;
    letter-spacing: 0px;
    max-width: 30rem;
  }

  .btn-dyo-banner {
    width: 100px;
    height: 30px;
    font-size: 12px;
    border-radius: 3px;
    border: none;
    background-color: #d02429;
    color: #fff;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

  .dyo-button {
    background-color: var(--dyo-base-red);
    color: var(--dyo-white);
    width: 140px;
    height: 35px;
    border: none;
    font-size: 12px;
  }

  .quick-book-iteams {
    max-width: 70%;
  }

  .quick-book-iteams h6 {
    font-weight: 600;
    font-size: 16px;
    padding: 19px 20px 5px 19px;
  }

  .quick-book-iteams p {
    font-weight: 400;
    padding: 10px 10px 17px 19px;
    font-size: var(--dyo-leading-14);
    color: var(--dyo-base-font);
  }

  .home {
    min-height: 77vh;
    display: flex;
    align-items: center;
    background: url("https://pub-3009e77e37704c5da613b25a085bcd33.r2.dev/NewBanner.svg");
    background-size: cover;
    background-position: center;
    height: 78vh;
}

  .home .content {
    max-width: 45rem;
    padding: 0px 0px 0px 12px;
  }

  .home .content h3 {
    font-size: 55px;
    color: #fff;
    font-family: 'Dancing Script';
    font-weight: 400;
  }

  .home .content p {
    font-size: 23px;
    font-weight: 500;
    padding:10px 0px;
    color: #fff;
    letter-spacing: 0px;
    max-width: 35rem;
  }

  .btn-dyo-banner {
    width: 125px;
    height: 35px;
    font-size: 14px;
    border-radius: 3px;
    border: none;
    background-color: #d02429;
    color: #fff;
  }
}
