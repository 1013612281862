
.profile-fields{
    border: #dee2e6 solid 1px;
    width: 100%;
    padding:1px 0px 3px 10px;
    border-radius: 5px;
    margin: 0px 0px 0px 10px;
    color: #888888;
    font-size: 20px;
}

.profile-image-items{
    margin: 70px 0 0 0;
}

.profile-edit{
        margin: 0px 114px 0px 0;
        border: none;
        background-color: #fff;
        color: #d02429;
}

.profile-edit i{
    font-size: 23px;
}

.back-button{
    font-size: 20px;
    text-decoration: none;
    color:#D02429;
}

.profile-gatway{
    margin: 60px 0px 0px 0px;
}

.profile-gatway h2{
    font-size: 45px;
}

.profile-inner-items label{
    color: #545454;
    font-size: 20px;
}

.profile-inner-items{
    margin: 152px 0px 0px 0px;
}

.profile-fields h6{
    font-size: 20px;
}

.profile-address p {
    font-size: 18px;
    margin: 5px auto;
}

@media only screen and (min-width:320px) and (max-width:600px){
    .back-button {
        font-size: 25px;
        text-decoration: none;
        color: #D02429;
    }

    .profile-gatway{
        margin: 20px 0px 0px 0px;
    }

    .profile-gatway h2{
        font-size: 16px;
    }

    .dyo-profile {
        width: 200px;
        height: 200px;
        margin: 0px 0px 0px 100px;
    }

    .dyo-profile-update{
            width: 200px;
            height: 200px;
            margin: 0px 0px 0px 0px;
    }

    .profile-image-items {
        margin: 20px 0 0 0;
    }

    .profile-fields {
        border: #dee2e6 solid 1px;
        width: 200px;
        padding: 3px 0px 3px 12px;
        border-radius: 2px;
        margin: 5px 10px 0px 10px;
        height: auto;
        font-size: 8px;
        color: #888888;
    }

    .address-layout p {
        font-size: 9px;
        margin: 0px auto;
    }

    .address-layout h6 {
        font-size: 9px;
        margin: 0px auto;
    }

    .profile-inner-items label{
        font-size: 10px;
    }

    .profile-inner-items{
        margin: 10px 0px 0px 70px;
    }
    
    .profile-fields h6 {
        font-size: 10px;
        margin: 0px auto;
    }

    .profile-address p {
        font-size: 8px;
        margin: 0px auto;
    }
}

@media only screen and (min-width:601px) and (max-width:768px){
    
    .dyo-profile {
        width: 200px;
        height: 200px;
        margin: 0px 0px 0px 160px;
    }

    .back-button {
        font-size: 30px;
        text-decoration: none;
        color: #D02429;
    }
}

@media only screen and (min-width:769px) and (max-width:992px){
    
    .dyo-profile {
        width: 200px;
        height: 200px;
        margin: 0px 0px 0px 0px;
    }

    .profile-inner-items {
        margin: 55px 0px 0px 0px;
    }

    .profile-fields {
        border: #dee2e6 solid 1px;
        width: 100%;
        padding: 1px 0px 3px 10px;
        border-radius: 5px;
        margin: 0px 0px 0px 30px;
        color: #888888;
        font-size: 20px;
    }

    .back-button {
        font-size: 32px;
        text-decoration: none;
        color: #D02429;
        margin: 6px 0 0 0;
    }
}

@media only screen and (min-width:993px) and (max-width:1200px){
    .back-button{
        font-size: 34px;
        text-decoration: none;
        color:#D02429;
    }
}

@media only screen and (min-width:1201px) and (max-width:1440px){
    .back-button{
        font-size: 40px;
        text-decoration: none;
        color:#D02429;
    }
}