
.getway-wrapper{
    background-image: url('../../images/auth_bg.png');
    background-repeat: no-repeat;
    background-size: 50%;
    height: 115vh;
  }
  
  
  .getway-inner {
    width: 50%;
    margin-left: calc(100% - 50%);
    padding: 70px 0 0 0;
    max-height: 700px;
    /* overflow-y: auto; */
  }

  .journal-getway-inner-items{
    
  }
  
  .getway-inner-items {
    max-width: 500px;
    margin: 0 auto;
  }

  .getway-inner-items-section{
    max-width: 500px;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 490px;
    height: 500px;
    scrollbar-width: none;
  }
  
  .getway-inner-items h2 {
    font-weight: 600;
    margin: 0;
  }
  
  .getway-inner-items span{
    color: #D02429;
  }
  
  .already-a-member{
    color: #888888;
  }
  
  .already-a-member label{
    padding: 0px 0px 0 10px;
  }
  
  .form-input{
    border-radius: 6px;
    background-color: #F5F6FA;
    color: #888888;
    font-size:14px;
    border: #F5F6FA;
    padding: 15px 22px;
  }
  
  .form-check-label{
    font-size: var(--dyo-leading-16);
    /* font-weight: lighter; */
    color: var(--dyo-base-font);
  }

  .login-getway-wrapper{
    background-image: url('../../images/auth_bg.png');
    background-repeat: no-repeat;
    background-size: 50%;
    min-height: 60vh; /* Chhoti screens ke liye */
    height: 90vh; /* Default height */
    max-height: 100vh; /* Maximum limit */
  }
  
  /* or */
  
  .or{
    position: relative;
    height: 1px;
    width: 100%;
    margin:11px 0 ;
  }
  
  .or::before{
    content: "OR";
    position: absolute;
    bottom: -2px;
    z-index: 99;
    color: #888888;
    margin: 0 auto;
    width: 35px;
    height: 12px;
    background: #fff;
    right: 0;
    left: 0;
    font-size: 14px;  
  }
  
  .or::after{
    content: "";
    width: 350px;
    height: 1px;
    position: absolute;
    background-color: #D9D9D9;
    bottom: 1px;
    right: 0;
     left: 0;
     margin: 0 auto;
  }
  
  
  .social-link{
    padding: 8px 10px;
    border-color: #D9D9D9;
    margin: 15px 15px 0 ;
    border-radius: 6px;
    border: none;
    box-shadow: 0px 0px 0px 0.1px;
  }


  @media (max-width:600px) {
    .getway-wrapper{
        background-image: url('../../images/auth_bg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        height: 60vh;
      }
      
      .login-getway-wrapper {
        background-image: url('../../images/auth_bg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        height: 60vh;
    }
      
      .getway-inner {
        background-color: #fff;
        width: 100%;
        margin-left: calc(50% - 50%);
        padding: 70px 20px 0 20px;
        max-height: 700px;
        overflow-y: auto;
    }
      
  }

  @media only screen and (min-width:601px) and (max-width:768px) {

    .getway-wrapper{
        background-image: url('../../images/auth_bg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        height: 60vh;
      }
      
      
      .getway-inner {
        background-color: #fff;
        width: 100%;
        margin-left: calc(50% - 50%);
        padding: 80px 20px 0 20px;
        max-height: 850px;
        overflow-y: auto;
    }

    .getway-inner-items {
        max-width: 500px;
        margin: 0 auto;
      }
      
  }

  @media only screen and (min-width:769px) and (max-width:992px) {

    .getway-wrapper{
        background-image: url('../../images/auth_bg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        height: 60vh;
      }
      
      .login-getway-wrapper {
        height: auto; /* Height ko content ke hisaab se adjust karne ke liye */
        min-height: 70vh;
        background-size: 80%;
        background-position: top center;
    }
      
      .getway-inner {
        background-color: #fff;
        width: 100%;
        margin-left: calc(50% - 50%);
        padding: 120px 10px 0 10px;
        max-height: 900px;
        overflow-y: auto;
    }

    .getway-inner-items {
        max-width: 650px;
        margin: 0 auto;
      }
      
  }

  @media only screen and (min-width:993px) and (max-width:1200px) {

    .getway-wrapper{
        background-image: url('../../images/auth_bg.png');
        background-repeat: no-repeat;
        background-size: 50%;
        height: 100vh;
      }
      
      
      .getway-inner {
        background-color: #ffff;
        width: 100%;
        height: 100%;
        margin-left: calc(50% - 50%);
        padding: 90px 0 0px 0;
        max-height: 1000px;
        overflow-y: auto;
      }
      
      .getway-inner-items {
        max-width: 800px;
        margin: 0 auto;
      }
      
      .getway-inner-items h2 {
        font-weight: 600;
        margin: 0;
      }
      
      .getway-inner-items span{
        color: #D02429;
      }
      
      .already-a-member{
        color: #888888;
      }
      
      .already-a-member label{
        padding: 0px 0px 0 10px;
      }
      
      .form-input{
        border-radius: 6px;
        background-color: #F5F6FA;
        color: #888888;
        font-size:14px;
        border: #F5F6FA;
        padding: 15px 22px;
      }
      
      .form-check-label{
        font-size: var(--dyo-leading-16);
        /* font-weight: lighter; */
        color: var(--dyo-base-font);
      }
      
      /* or */
      
      .or{
        position: relative;
        height: 1px;
        width: 100%;
        margin:11px 0 ;
      }
      
      .or::before{
        content: "OR";
        position: absolute;
        bottom: -2px;
        z-index: 99;
        color: #888888;
        margin: 0 auto;
        width: 35px;
        height: 12px;
        background: #fff;
        right: 0;
        left: 0;
        font-size: 14px;  
      }
      
      .or::after{
        content: "";
        width: 350px;
        height: 1px;
        position: absolute;
        background-color: #D9D9D9;
        bottom: 1px;
        right: 0;
         left: 0;
         margin: 0 auto;
      }
      
      
      .social-link{
        padding: 8px 10px;
        border-color: #D9D9D9;
        margin: 15px 15px 0 ;
        border-radius: 6px;
        border: none;
        box-shadow: 0px 0px 0px 0.1px;
      }
      
  }

  @media only screen and (min-width:1201px) and (max-width:1440px) {

    .getway-wrapper{
        background-image: url('../../images/auth_bg.png');
        background-repeat: no-repeat;
        background-size: 50%;
        height: 100vh;
      }
      
      
      .getway-inner {
        width: 50%;
        margin-left: calc(100% - 50%);
        padding: 90px 0 30px 0;
        max-height: 900px;
        overflow-y: auto;
      }
      
      .getway-inner-items {
        max-width: 415px;
        margin: 0 auto;
      }
      
      .getway-inner-items h2 {
        font-weight: 600;
        margin: 0;
      }
      
      .getway-inner-items span{
        color: #D02429;
      }
      
      .already-a-member{
        color: #888888;
      }
      
      .already-a-member label{
        padding: 0px 0px 0 10px;
      }
      
      .form-input{
        border-radius: 6px;
        background-color: #F5F6FA;
        color: #888888;
        font-size:14px;
        border: #F5F6FA;
        padding: 15px 22px;
      }
      
      .form-check-label{
        font-size: var(--dyo-leading-16);
        color: var(--dyo-base-font);
      }
      
      
      .or{
        position: relative;
        height: 1px;
        width: 100%;
        margin:11px 0 ;
      }
      
      .or::before{
        content: "OR";
        position: absolute;
        bottom: -2px;
        z-index: 99;
        color: #888888;
        margin: 0 auto;
        width: 35px;
        height: 12px;
        background: #fff;
        right: 0;
        left: 0;
        font-size: 14px;  
      }
      
      .or::after{
        content: "";
        width: 350px;
        height: 1px;
        position: absolute;
        background-color: #D9D9D9;
        bottom: 1px;
        right: 0;
         left: 0;
         margin: 0 auto;
      }
      
      
      .social-link{
        padding: 8px 10px;
        border-color: #D9D9D9;
        margin: 15px 15px 0 ;
        border-radius: 6px;
        border: none;
        box-shadow: 0px 0px 0px 0.1px;
      }
      
  }
  