
  .phone-input-wrapper {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between country code and input */
    width: 100%; /* Full width */
    max-width: 400px; /* Optional: max width */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
}

.login-form-input {
    flex: 1; /* Input ko remaining space occupy karne do */
    border: none;
    outline: none;
    font-size: 16px;
    padding: 8px;
    width: '100px';
}

.btn-width{
  width: 400px;
} 

  /* --- Login Wrapper --- */
.login-wrapper {
  background-image: url('../../images/auth_bg.png');
    background-repeat: no-repeat;
    background-size: 50%;
    height: 92vh;
}

/* --- Login Container --- */
.login-container {
  width: 50%;
    margin-left: calc(100% - 50%);
    padding: 170px 0 0 0;
    max-height: 700px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-self: center;
}

/* --- Title Styling --- */
.login-container h2 {
  font-weight: 600;
  margin-bottom: 50px;
}

.login-container span {
  color: #D02429;
}

/* --- Form Styling --- */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-label {
  font-size: 14px;
  color: #555;
  text-align: left;
}

/* --- Phone Input Wrapper --- */
.phone-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding:0px 15px;
  background: #F5F6FA;
}

.country-code {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 0 10px 0 0;
}

/* --- Input Field --- */
.login-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  background: transparent;
}

/* --- Button Styling --- */
.btn-primary {
  background-color: #D02429;
  color: white;
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
}

.btn-primary:hover {
  background-color: #A01B21;
}

/* otp css */
.otp-content{
  font-size: 18px;
  margin: 0;
  color: #2a2b2c;
}

.otp-input{
  width: 40px;
  height: 40px;
  font-Size: 18px;
  text-Align: center;
  border: 2px solid #dfdada;
  border-Radius: 8px;
  margin: 0 5px;
  color:'#2a2b2c';
}

.error-text {
  color: #d02429;
  font-size: 10px;
  margin-top: 0px;
}

/* .error-border {
  border: 2px solid red;
} */

@media (max-width: 600px) {
  .login-wrapper {
    background-image: none;
    background-repeat: no-repeat;
    background-size: 100%;
    height: auto;
    padding: 20px;
  }

  .login-container {
    width: 100%;
    margin-left: 0;
    padding: 50px 20px 0 20px;
    max-height: none;
    justify-content: center;
    align-items: center;
  }

  .btn-width {
    width: 100%;
  }

  .phone-input-wrapper {
    max-width: 100%;
    width: 100%;
  }

  .login-input {
    font-size: 14px;
    padding: 10px;
  }

  .otp-input {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }
}


@media only screen and (min-width:601px) and (max-width:768px) {
  .login-wrapper {
    background-image:none;
      background-repeat: no-repeat;
      background-size: 100%;
      height: auto;
  }

  .login-container {
    width: 100%;
    margin-left: 0;
    padding: 88px 0 0 0;
    max-height: 700px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
}
}