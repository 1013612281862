
.payment-gatway {
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 20px; 
    margin-top: 60px;
}

.payment-iteams {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    border: 1px solid #EEEEEE;
    border-radius: 8px; 
    background-color: #F7F7F7; 
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); 
    max-width: 550px; 
    width: 100%; 
}

.payment-amount {
    display: flex;
    align-items: center;
    margin-top:20px;
}


.payment-amount i {
    margin-right: 5px;
    font-size: 30px;
}

.payment-iteams h6 {
    margin: 20px 0 0 0;
    font-size:25px;
    font-weight:600;
}

.payment-iteams p {
    margin: 10px 30px;
    font-size:18px;
    font-weight: 500;
    color: #545454;
    margin-top: 40px;
}

.d-flex h2 {
    margin: 0;
}

.pay-button {
    background-color: #d02429; 
    color: #fff; 
    border: none;
    border-radius: 4px; 
    cursor: pointer; 
    font-size: 16px; 
    margin: 20px 0px; 
    width: 350px;
    height: 35px;
    justify-content: center;
    align-items: center;
}

.pay-button:hover {
    background-color: #C1070C; 
}

.back-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
}

.back-button i {
    font-size: 30px; 
}
@media (max-width: 600px) {
    .payment-gatway {
        padding: 10px;
        margin-top: 30px;
    }

    .payment-iteams {
        max-width: 90%;
        padding: 15px;
        border-radius: 6px;
    }

    .payment-iteams h6 {
        font-size: 20px;
        margin: 10px 0;
    }

    .payment-iteams p {
        font-size: 14px;
        margin: 10px 15px;
    }

    .payment-amount i {
        font-size: 24px;
    }

    .payment-amount h2 {
        font-size: 22px;
    }

    .pay-button {
        width: 100%;
        height: 40px;
        font-size: 14px;
        margin-top: 15px;
    }

    .back-button i {
        font-size: 24px;
    }

    .back-button h5 {
        font-size: 18px;
    }
}


@media only screen and (min-width:601px) and (max-width:767px) {
    .payment-iteams {
        max-width: 400px;
    }
}

@media (max-width: 768px) {
    .payment-iteams {
        padding: 15px; 
    }

    .pay-button {
        width: 100%; 
    }

    .payment-iteams h6 {
        margin: 10px 0 0 0;
        font-size: 20px;
    }

    .payment-iteams p {
        margin: 10px 30px;
        font-size: 14px;
        margin-top: 25px;
    }

    .d-flex {
        margin-top: 5px;
    }

    .d-flex i{
        font-size: 20px;
    }

    .d-flex h2{
        font-size: 20px;
    }

    .pay-button {
        font-size: 12px;
        width: 300px;
        height: 29px;
    }
}
