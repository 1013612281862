.payment-section {
  background-color: #fff;
  height: 92vh;

}

.payment {
  /* padding: 100px 0 0 0px; */
  display: flex;
  flex-direction:column ;
  justify-content: center;
  align-items: center;
}

.payment-gateway {
  margin: 100px 0 0 0px;
  width: 737px;
  height: 450px;
  background-color: #fff;
  border-radius: 5px;
}

.payment-gateway-inner {
  padding: 50px 0px 0px 0px;
}

.payment-gateway-inner h1 {
  font-size: 50px;
  color: #000;
  font-weight: 500;
}

.payment-gateway-inner-iteam {
  margin: 20px 0 0 0;
}

.payment-gateway-inner-iteam h6 {
  font-size: 20px;
}

.canvas {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
}

.pop-outin {
  animation: anim-popoutin 2s ease infinite;
}

.btn-close {
  position: absolute;
  transform: translateX(-50px);
  margin: 100px 40px 0px 575px;
}

.payment-logo {
  width: 103px;
}


.btn-back {
  margin: 61px 0 0 235px;
  width: 100px;
  border-radius: 20px;
  border: #d02429 solid 1px;
  background-color: #d02429;
  color: #fff;
}

.payment-button {
  width: 450px;
  height: 40px;
  border-radius: 6px;
  color: #fff;
  background-color: #d02429;
  border: 10px;
  font-size: 15px;
}

@media (max-width: 600px) {
  .payment-section {
      height: auto;
      padding: 20px;
      text-align: center;
  }

  .payment {
      justify-content: center;
      align-items: center;
      padding: 10px;
  }

  .payment-gateway {
      width: 90%;
      height: auto;
      margin: 20px auto;
      padding: 20px;
      border-radius: 8px;
  }

  .payment-gateway-inner h1 {
      font-size: 20px;
      margin: 15px 0;
  }

  .payment-logo {
      width: 80px;
      margin-bottom: 10px;
  }

  .payment-button {
      width: 200px;
      height: 40px;
      font-size: 14px;
      margin-top: 15px;
  }

  .btn-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
  }
}



@media only screen and (min-width: 601px) and (max-width: 768px) {
  .payment-gateway {
    margin: 85px 0 0 0px;
    width: 520px;
    height: 400px;
  }

  .payment-gateway-inner {
    padding: 0px 0px 0px 0px;
  }

  .payment-gateway-inner h1 {
    font-size: 20px;
    margin: 20px 0px;
  }

  .payment-gateway-inner-iteam h6 {
    font-size: 14px;
  }

  .payment-gateway-inner-iteam p {
    font-size: 12px;
    margin: 2px auto;
  }

  /* .payment {
    padding: 15px 0 0 50px;
  } */

  .payment-logo {
    width: 125px;
    height: 125px;
  }

  .payment-button {
    width: 450px;
    height: 35px;
    border-radius: 6px;
    color: #fff;
    background-color: #d02429;
    border: 10px;
    font-size: 12px;
  }

  .btn-close {
    position: absolute;
    transform: translateX(-50px);
    margin: 80px 0px 0px 320px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .payment-gateway {
    margin: 85px 0 0 0px;
    width: 520px;
    height: 400px;
  }

  .payment-gateway-inner h1 {
    font-size: 25px;
    margin: 20px 0px;
  }

  .payment-gateway-inner-iteam h6 {
    font-size: 14px;
  }

  .payment-gateway-inner-iteam p {
    font-size: 12px;
    margin: 2px auto;
  }

  /* .payment {
    padding: 20px 0 0 60px;
  } */

  .payment-gateway-inner {
    padding: 0px 0px 0px 0px;
  }

  .payment-logo {
    width: 103px;
  }

  .payment-button {
    width: 494px;
    height: 40px;
    border-radius: 6px;
    color: #fff;
    background-color: #d02429;
    border: 10px;
    font-size: 14px;
  }

  .btn-close {
    position: absolute;
    transform: translateX(-50px);
    margin: 70px 0px 0px 370px;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .payment-gateway {
    margin: 0px 0 0 0px;
    width: 700px;
    height: 450px;
  }

  .payment-gateway-inner {
    padding: 30px 0px 0px 0px;
  }

  .payment-gateway-inner h1 {
    font-size: 40px;
    margin: 30px 0px 25px 0px;
  }

  .payment-gateway-inner-iteam h6 {
    font-size: 14px;
  }

  .payment-gateway-inner-iteam p {
    font-size: 14px;
    margin: 2px auto;
  }

  /* .payment {
    padding: 1px 0 0 60px;
  } */

  .payment-logo {
    width: 125px;
  }

  .payment-button {
    width: 500px;
    height: 50px;
    border-radius: 6px;
    color: #fff;
    background-color: #d02429;
    border: 10px;
    font-size: 16px;
  }

  .btn-close {
    position: absolute;
    transform: translateX(-50px);
    margin: 122px 0px 0px 450px;
}
}

