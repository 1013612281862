.w3-sidebar {
    height: 100%;
    width: 75px;
    background-color: #fff;
    position: fixed !important;
    z-index: 1;
    overflow: auto;
    padding: 37px 0px 0px 0px;
    border-color: #D9D9D9;
    box-shadow: 0px 0px 0.9px 0.1px;
    position: fixed;
  }
 
  .w3-sidebar i {
    font-size: 21px;
  }

  .w3-sidebar a:hover {
    color: #D02429;
  }

  .w3-bar-item p {
    font-size: 12px;
  }

  .w3-button {
    border: none;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 16px;
  }


  .dashboard-scroller {
    overflow-y: auto;
    overflow-x: none;
    max-height: 600px;
    padding-bottom: 40px;
  }

  .seprator-50 {
    margin: 50px 0px 0px 122px;
  }
 
  .seprator-50 h4 {
    font-size: 22px;
  }

  .card-container {
    margin: 32px 0px 0px 0px
  }

  .card-container a{
    text-decoration: none;
  }

  .card-container h6{
    color: #000;
  }

  .booking-card {
    width: 400px;
    height: 85px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 0.6px #E4E4E4;
    padding: 13px 0px 0px 20px;
  }

  .booking-card p {
    font-size: 14px;
  }
 
  .booking-card h6 {
    margin: 0 auto;
  }

  .booking-card-head {
    border-bottom: solid #000 1px;
  }

  .dashboard-navigation {
    padding: 6px 0px 0px 15px;
  }
 
  .dashboard-navigation p {
    font-size: 14px;
    margin: 3px auto;
    color: #545454;
  }

  .parformance-card {
    width: 243px;
    height: 83px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 0.6px #E4E4E4;
    padding: 13px 0px 0px 20px;
  }

  .dashboard-scroller {
    overflow-y: auto;
    max-height: 600px;
    padding-bottom: 40px;
  }

  .seprator-45 {
    margin: 45px 0px 0px 168px;
  }

  .feedback-card {
    padding: 20px 23px 0px 23px;
    box-shadow: 0px 0px 0px 0.6px #E4E4E4;
    border-radius: 5px;
    width: 276px;
    height: 127px;
  }
 
  .feedback-card-iteam {
    padding: 0px 0px 0px 0px;
  }
 
  .feedback-card p {
    font-size: 10px;
  }

  .feedback-card-iteam {
    padding: 0px 0px 0px 0px;
  }

  .feedback-bottom img {
    width: 20px;
    height: 20px;
  }

  
  @media only screen and (min-width:320px) and (max-width:600px) {
    
    .w3-sidebar {
        height: 100%;
        width: 60px;
        background-color: #fff;
        position: fixed !important;
        z-index: 1;
        overflow: auto;
        padding: 37px 0px 0px 0px;
        border-color: #D9D9D9;
        box-shadow: 0px 0px 0.9px 0.1px;
        position: fixed;
      }
   
      .w3-sidebar i {
        font-size: 15px;
      }

      .w3-bar-item p {
        font-size: 10px;
      }

      .w3-bar-item img {
        width: 14px;
        height: 15px;
      }

      .seprator-50 {
        margin: 40px 0px 0px 70px
      }

      .seprator-45 {
        margin: 35px 0px 0px 76px;
      }   
   
      .seprator-50 h4 {
        font-size: 18px;
      }
   
      .card-container {
        margin: 20px 0px 0px 0px
      }

      .booking-card {
        width: 100%;
        height: 65px;
        border-radius: 5px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        padding: 10px 0px 0px 20px;
        margin: 5px 0px;
      }
   
      .booking-card img {
        width: 40px;
        height: 40px;
      }

      .dashboard-navigation {
        padding: 0px 0px 0px 15px;
      }

      .dashboard-navigation p {
        font-size: 9px;
        margin: 3px auto;
        color: #545454;
      }

      .parformance-card {
        width: 100%;
        height: 65px;
        border-radius: 5px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        padding: 13px 0px 0px 20px;
        margin: 5px 0px;
      }
   
      .parformance-card img {
        width: 40px;
        height: 40px;
      }

      .feedback-card {
        padding: 15px 10px 0px 10px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        border-radius: 5px;
        margin: 5px 2px;
        width: 95%;
        height: 125px;
      }

}

@media only screen and (min-width:601px) and (max-width:768px) {

    .w3-sidebar {
        height: 100%;
        width: 60px;
        background-color: #fff;
        position: fixed !important;
        z-index: 1;
        overflow: auto;
        padding: 37px 0px 0px 0px;
        border-color: #D9D9D9;
        box-shadow: 0px 0px 0.9px 0.1px;
        position: fixed;
      }
   
      .w3-sidebar i {
        font-size: 15px;
      }

      .w3-bar-item p {
        font-size: 10px;
      }

      .w3-bar-item img {
        width: 14px;
        height: 15px;
      }

      .seprator-45 {
        margin: 45px 0px 0px 100px;
      }

      .seprator-50 {
        margin: 40px 0px 0px 70px
      }
   
      .seprator-50 h4 {
        font-size: 18px;
      }

      .card-container {
        margin: 20px 0px 0px 0px
      }

      .booking-card {
        width: 100%;
        height: 65px;
        border-radius: 5px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        padding: 10px 0px 0px 20px;
        margin: 5px 0px;
      }
   
      .booking-card img {
        width: 40px;
        height: 40px;
      }

      .dashboard-navigation {
        padding: 0px 0px 0px 15px;
      }

      .dashboard-navigation p {
        font-size: 9px;
        margin: 3px auto;
        color: #545454;
      }

      .parformance-card {
        width: 100%;
        height: 65px;
        border-radius: 5px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        padding: 13px 0px 0px 20px;
        margin: 5px 0px;
      }
   
      .parformance-card img {
        width: 40px;
        height: 40px;
      }

      .feedback-card {
        padding: 15px 10px 0px 10px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        border-radius: 5px;
        margin: 5px 2px;
        width: 350px;
        height: 125px;
      }

}

@media only screen and (min-width:769) and (max-width:991){

    .w3-sidebar {
        height: 100%;
        width: 60px;
        background-color: #fff;
        position: fixed !important;
        z-index: 1;
        overflow: auto;
        padding: 37px 0px 0px 0px;
        border-color: #D9D9D9;
        box-shadow: 0px 0px 0.9px 0.1px;
        position: fixed;
      }
   
      .w3-sidebar i {
        font-size: 15px;
      }

      .w3-bar-item p {
        font-size: 10px;
      }
   
      .w3-bar-item img {
        width: 14px;
        height: 15px;
      }

      .seprator-45 {
        margin: 45px 0px 0px 75px;
      }

      .seprator-50 {
        margin: 40px 0px 0px 70px
      }
   
      .seprator-50 h4 {
        font-size: 18px;
      }

      .card-container {
        margin: 20px 0px 0px 0px
      }

      .booking-card {
        width: 100%;
        height: 65px;
        border-radius: 5px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        padding: 10px 0px 0px 20px;
        margin: 5px 0px;
      }
   
      .booking-card img {
        width: 40px;
        height: 40px;
      }

      .dashboard-navigation {
        padding: 0px 0px 0px 15px;
      }

      .dashboard-navigation p {
        font-size: 12px;
        margin: 3px auto;
        color: #545454;
      }

      .parformance-card {
        width: 100%;
        height: 65px;
        border-radius: 5px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        padding: 13px 0px 0px 20px;
        margin: 5px 0px;
      }
   
      .parformance-card img {
        width: 40px;
        height: 40px;
      }

      .feedback-card {
        padding: 11px 10px 0px 10px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        border-radius: 5px;
        width: 100%;
        height: 127px;
      }

      .feedback-bottom {
        font-size: 9px;
      }
   
      .feedback-bottom i {
        margin: 6px 0px 0px 0px;
      }
   
}

@media only screen and (min-width:992) and (max-width:1200){

    .w3-sidebar {
        height: 100%;
        width: 70px;
        background-color: #fff;
        position: fixed !important;
        z-index: 1;
        overflow: auto;
        padding: 37px 0px 0px 0px;
        border-color: #D9D9D9;
        box-shadow: 0px 0px 0.9px 0.1px;
        position: fixed;
      }
   
      .w3-sidebar i {
        font-size: 24px;
      }

      .w3-bar-item p {
        font-size: 12px;
      }
   
      .w3-bar-item img {
        width: 14px;
        height: 15px;
      }

      .seprator-45 {
        margin: 45px 0px 0px 75px;
      }

      .seprator-50 {
        margin: 40px 0px 0px 90px
      }
   
      .seprator-50 h4 {
        font-size: 18px;
      }

      .card-container {
        margin: 20px 0px 0px 0px
      }

      .booking-card {
        width: 100%;
        height: 65px;
        border-radius: 5px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        padding: 10px 0px 0px 20px;
        margin: 5px 0px;
      }
   
      .booking-card img {
        width: 40px;
        height: 40px;
      }

      .dashboard-navigation {
        padding: 0px 0px 0px 15px;
      }

      .dashboard-navigation p {
        font-size: 12px;
        margin: 3px auto;
        color: #545454;
      }

      .parformance-card {
        width: 100%;
        height: 65px;
        border-radius: 5px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        padding: 13px 0px 0px 20px;
        margin: 5px 0px;
      }
   
      .parformance-card img {
        width: 40px;
        height: 40px;
      }

      .feedback-card {
        padding: 11px 10px 0px 10px;
        box-shadow: 0px 0px 0px 0.6px #E4E4E4;
        border-radius: 5px;
        
        width: 100%;
        height: 127px;
      }

      .feedback-card p {
        font-size: 12px;
      }

      .feedback-bottom {
        font-size: 15px;
      }
   
      .feedback-bottom i {
        margin: 6px 0px 0px 0px;
      }

}