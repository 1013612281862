.table-heading{
    margin: 0px 150px 0px 0px;
}

.status-symbol{
    margin: 0px 250px 0px 0px;
}

.table-filter{
    margin: 0px 150px 0px 0px;
}

.booking-table-head{
    display: flex;
    margin: 20px 0px 20px 0px;
}

.filter-button{
    width: 200px;
}

@media only screen and (min-width:320px) and (max-width:600px){
    .table-heading{
        margin: 0px 0px 0px 0px;
    }
    
    .status-symbol{
        margin: 10px 0px 0px 0px;
    }
    
    .table-filter{
        margin: 10px 0px 10px 0px;
    }

    .booking-table-head{
        display: contents;
        margin: 20px 0px 20px 0px;
    }

    .table-search{
        margin: 0px 0px 10px 0px;
    }

    .table-search input{
        height: 20px;
        font-size: 12px;
    }

    table th{
        font-size: 10px;
        padding: 0px 0px 0px 0px;
    }

    table td{
        font-size: 10px;
    }

    .filter-button {
        width: 70px;
        height: 20px;
        font-size: 12px;
    }

    .dropdown-menu{
        font-size: 12px;
    }

}

@media only screen and (min-width:601px) and (max-width:768px){
    .table-heading{
        margin: 0px 0px 0px 0px;
    }
    
    .status-symbol{
        margin: 5px 0px 0px 0px;
    }
    
    .table-filter{
        margin: 10px 0px 10px 0px;
    }

    .booking-table-head{
        display: contents;
        margin: 20px 0px 20px 0px;
    }

    .table-search{
        margin: 0px 0px 10px 0px;
    }

    .table-search input{
        height: 30px;
    }

    table th{
        font-size: 10px;
        padding: 0px 0px 0px 0px;
    }

    table td{
        font-size: 10px;
    }

}

@media only screen and (min-width:769px) and (max-width:991px){
    .table-heading{
        margin: 0px 0px 0px 0px;
    }
    
    .status-symbol{
        margin: 5px 0px 0px 0px;
    }
    
    .table-filter{
        margin: 10px 0px 10px 0px;
    }

    .booking-table-head{
        display: contents;
        margin: 20px 0px 20px 0px;
    }

    .table-search{
        margin: 0px 0px 10px 0px;
    }

    .table-search input{
        height: 30px;
    }

    table th{
        font-size: 10px;
        padding: 0px 0px 0px 0px;
    }

    table td{
        font-size: 10px;
    }

}

@media only screen and (min-width:992px) and (max-width:1200px){
    .table-heading{
        margin: 0px 50px 0px 0px;
    }
    
    .status-symbol{
        margin: 5px 80px 0px 0px;
    }
    
    .table-filter{
        margin: 0px 80px 10px 0px;
    }

    .booking-table-head{
        display: flex;
        margin: 20px 0px 20px 0px;
    }

    .table-search{
        margin: 0px 0px 10px 0px;
    }

    .table-search input{
        height: 30px;
    }

    table th{
        font-size: 10px;
        padding: 0px 0px 0px 0px;
    }

    table td{
        font-size: 10px;
    }

}